@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@100;300;400;900&display=swap');

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fadeIn {
  animation: fadeIn 1s ease-in forwards;
}


body {
  margin: 0;
  font-family: "Work Sans", Monaco, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.londrina-solid-thin {
  font-family: "Londrina Solid", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.londrina-solid-light {
  font-family: "Londrina Solid", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.londrina-solid-regular {
  font-family: "Londrina Solid", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.londrina-solid-black {
  font-family: "Londrina Solid", sans-serif;
  font-weight: 900;
  font-style: normal;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.css-13cymwt-control{
  background-color: #4F5468 !important;
  border-color: #676d84 !important;
  /* is focussed */
  &:hover {
    border-color: #676d84 !important;
  }
  &:focus {
    border-color: #676d84 !important;
    background-color: #4F5468 !important;
  }
}

.loader-icon {
  display: inline-block;
  height: 6.4rem;
  width: 6.4rem;
  border-width: 0.2rem;
  border-style: solid;
  border-color: #ff9b00;
  position: relative;
  border-radius: 50%;
  margin-bottom: 2.5rem;
  -webkit-animation: rotate 0.3s linear infinite;
  animation: rotate 0.3s linear infinite;
}

.ql-toolbar.ql-snow {
  border: none !important;
  box-sizing: border-box;
  font-family: inherit !important;
  padding: 8px;
}
.ql-container.ql-snow {
  border: none !important;
}
